/**
 * Class element
 * Header Article component
 * Display the hero of the post article page
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import Socialshare from "../components/socialshare"
import Titledecorative from "../components/titledecorative"
import "../styles/components/headerarticle.scss"

/* Declaration class */
class Headerarticle extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      stickyNav: false,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
        stickyNav: this.state.stickyNav,
      })
    }
  }

  handleScroll = () => {
    var mainheader = [].slice.call((document.getElementsByClassName("header-article")))
    var wrapheadersticky = [].slice.call((document.getElementsByClassName("wrap-header-article-sticky")))
    var articleSection = [].slice.call((document.getElementsByClassName("article-post")))
    const isTablet = this.state.width <= 1280
    const isMobile = this.state.width <= 480
    var heightSticky = 600
    var offsetHeightArticle = articleSection[0].offsetHeight

    if(isTablet) {
      heightSticky = 500
    } else if (isMobile) {
      heightSticky = 400
    }

    var sumHeight = heightSticky + offsetHeightArticle

    if((heightSticky < document.body.scrollTop && document.body.scrollTop < sumHeight) || (heightSticky < document.documentElement.scrollTop && document.documentElement.scrollTop < sumHeight)) {
      this.setState({
        width: window.innerWidth,
        stickyNav: true,
      })
      mainheader[0].classList.add("sticky")
      wrapheadersticky[0].classList.add("display")
    }else{
      this.setState({
        width: window.innerWidth,
        stickyNav: false,
      })
      mainheader[0].classList.remove("sticky")
      wrapheadersticky[0].classList.remove("display")
    }
  }

  render () {

    var ctaLabelBack = this.props.ctaLabelBack
    var catSlugPost = this.props.catSlugPost
    var dateArticle = this.props.dateArticle
    var titleArticle = this.props.titleArticle
    var authorArticle = this.props.authorArticle
    var imgArticle = this.props.imgArticle
    var urlShareArticle = this.props.urlShareArticle
    var leadShareArticle = this.props.leadShareArticle


    return (
      <header className="header-article">
        <div className="wrap-header-article-sticky">
          <div className="wrap-maincontent flex-header">
            <div className="back-article">
              <Ctabuttonwitharrow ctaLabel={ctaLabelBack} ctaLink={`/${catSlugPost}`} colorStyle="white-format" specificStyle="text-arrow" arrow="left" />
            </div>
            <p className="style-h1">{titleArticle}</p>
            <div className="box-share">
              <Socialshare urlArticle={urlShareArticle} titleArticle={titleArticle} leadArticle={leadShareArticle} topBanner="topbanner"/>
            </div>
          </div>
        </div>
        <div className="wrap-header-article">
          <div className="back-article">
            <Ctabuttonwitharrow ctaLabel={ctaLabelBack} ctaLink={`/${catSlugPost}`} colorStyle="white-format" specificStyle="text-arrow" arrow="left" />
          </div>
          <div className="content-header-article">
            <div className="box-content">
              <p className="type-date"><span>{dateArticle}</span></p>
              <Titledecorative titleType="h1" title={titleArticle} />
              <p className="type-author">{authorArticle}</p>
            </div>
            <div className="box-picture">
              <GatsbyImage image={imgArticle} alt={`picture ${titleArticle}`} />
            </div>

            <div className="box-share">
              <Socialshare urlArticle={urlShareArticle} leadArticle={leadShareArticle} />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

/* Export class */
export default Headerarticle
