/**
 * Const with params element
 * Banner with CTA component
 * Display a banner with a cta
 */
/* Import section */
import PropTypes from "prop-types"
import React from "react"
import Ctabutton from "../components/ctabutton"
import "../styles/components/Bannerwithcta.scss"

/* Declaration function */
const Bannerwithcta = ( { classBanner, titleBanner, textBanner, ctaLabelBanner, ctaLinkBanner } ) => {
  return (
    <section className={`section-banner ${classBanner}`}>
      <div className="banner-withcta">
        <div className="wrap-text-banner">
          <h3 dangerouslySetInnerHTML={{ __html:titleBanner}} />
          <div className="content-banner">
            <div className="separator-banner"></div>
            <div className="text-banner" dangerouslySetInnerHTML={{ __html:textBanner}} />
            <Ctabutton ctaLabel={ctaLabelBanner} ctaLink={ctaLinkBanner} colorStyle="white-format" />
          </div>
        </div>
      </div>
    </section>
  )
}

/* Function propTypes declaration */
Bannerwithcta.propTypes = {
  classBanner: PropTypes.string,
  titleBanner: PropTypes.string,
  textBanner: PropTypes.string,
  ctaLabelBanner: PropTypes.string,
  ctaLinkBanner: PropTypes.string,
}

Bannerwithcta.defaultProps = {
  classBanner: "",
  titleBanner: "Title Banner",
  textBanner: "Text Banner",
  ctaLabelBanner: "Read More",
  ctaLinkBanner: "",
}


/* Export function */
export default Bannerwithcta
