/**
 * Const with params element
 * Block aside component
 * Display a block of content on aside article
 */
/* Import section */
import React from "react"
import "../styles/components/blockaside.scss"

/* Declaration function */
const Blockaside = ( { keyAside, idAside, contentAside, blocAside} ) => {
  return (
    <div key={keyAside} id={idAside} className={`block-aside ${blocAside}`}>
      {blocAside === "bloc2" && <span className="decorative"></span>}
      {(blocAside === "bloc1" || blocAside === "bloc2") && <div dangerouslySetInnerHTML={{ __html:contentAside}}/> }
      {blocAside === "bloc3" && <blockquote><div className="box-blockquote" dangerouslySetInnerHTML={{ __html:contentAside}}/></blockquote>}
    </div>
  )
}

/* Export function */
export default Blockaside

