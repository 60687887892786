/**
 * Const with params element
 * Card related post component
 * Display a card of related post
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import TruncateText from "../components/truncatetext"
import "../styles/components/cardrelatedpost.scss"

/* Declaration function */
const Cardrelatedpost = ({ titlePost, srcImgPost, slugPost, datePost, typeSlugPost }) => {
  return (
    <div className="card-related-post card-clickable">
      <div className="box-image-card">
        <GatsbyImage image={srcImgPost} alt={`picture ${titlePost}`} />
      </div>
      <p className="type-date"><span>{datePost}</span></p>
      <h2><TruncateText str={titlePost} length="60" ending="..." /></h2>
      <div className="box-ctatext-card">
        <Ctabuttonwitharrow ctaLabel="Read More" ctaLink={`/${typeSlugPost}/${slugPost}`} specificStyle="text-arrow" colorStyle="white-format" />
      </div>
      <a href={`/${typeSlugPost}/${slugPost}/`} aria-label="Read More"><span className="span-cta-card" >Read More</span></a>
    </div>
  )
}

/* Export function */
export default Cardrelatedpost

