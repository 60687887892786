/**
 * Post Template
 */
/* Import section */
import { graphql } from "gatsby"
import { siteMetadata } from "../../gatsby-config"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Blockaside from "../components/blockaside"
import Cardrelatedpost from "../components/cardrelatedpost"
import Ctabutton from "../components/ctabutton"
import Headerarticle from "../components/headerarticle"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/templates/post.scss"

/* Declaration function */
const PostTemplate = ({ data }) => {

  //Const data page
  const dataPost = data.wpPost
  const catNamePost = dataPost.categories.nodes[0].name
  const catSlugPost = dataPost.categories.nodes[0].slug
  const ctaLabelBack = 'Back to '+catNamePost
  const urlSharePost= siteMetadata.siteUrl+'/'+catSlugPost+'/'+dataPost.slug
  const introPost = dataPost.acfPostBlogNews.leadArticle
  const picturePost = dataPost.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData
  var repeaterAsideArticle = dataPost.acfPostBlogNews.repeaterArticle
  var classRepeater = (repeaterAsideArticle != null) ? "post-repeater" : "post-static"

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "getstarted1"
  })

  return (
    <Layout>
      <Seo
        title={dataPost.seo.title}
        description={dataPost.seo.metaDesc}
        datastructured="article"
        articleId={dataPost.databaseId}
        articlePicture={picturePost}
      />
      <section className="section-post-article top-section">
        <article className="article-post">
          <Headerarticle
            ctaLabelBack = {ctaLabelBack}
            catSlugPost= {catSlugPost}
            dateArticle = {dataPost.date}
            titleArticle = {dataPost.title}
            authorArticle = {dataPost.author.node.name}
            imgArticle = {picturePost}
            urlShareArticle = {urlSharePost}
            leadShareArticle = {dataPost.seo.metaDesc}
          />
          <section className="content-article">
            <div className="wrap-content-article wrap-maincontent-static">
              {introPost !== "" &&
                <div className={`lead-content-article maincontent-static post-content ${classRepeater}`} dangerouslySetInnerHTML={{ __html:introPost}} />
              }
              <div className="main-content-article">
                {repeaterAsideArticle !== null ? (
                  <div className="post-repeater">
                  {dataPost.acfPostBlogNews.repeaterArticle.map( (item, index )  => (
                    <div className="repeater-aside-article">
                      <div className="repeater-content">
                        <h2>{item.titleH2Section}</h2>
                        <div className="post-content" dangerouslySetInnerHTML={{ __html:item.contentSection}} />
                      </div>
                      {item.blocAsideSection !== null && (
                        <div className="repeater-aside">
                          <Blockaside keyAside={index} contentAside={item.blocAsideSection} blocAside={item.typeBlocAside} />
                        </div>
                      )}
                    </div>
                  ))}
                  </div>
                ) : (
                  <div className="maincontent-static post-content post-static" dangerouslySetInnerHTML={{ __html:dataPost.content}} />
                )}
              </div>
            </div>
          </section>
        </article>
        <aside>
          {/* Section with loop on tag */}
          <section className="section-tags">
            {dataPost.tags != null && (
              <ul className="list-tag">
                {dataPost.tags.nodes.map(({ name, slug }) => (
                  <li key={slug}><Ctabutton ctaLabel={name} ctaLink={`/tag/${slug}`} /></li>
                ))}
              </ul>
            )}
          </section>
          {/* Section about the Author */}
          <section className="section-author">
            <div className="title-section-aside">
              <h2>About the Author</h2>
            </div>
            <div className="box-author">
              <div className="picture-author">
                {dataPost.author.node.databaseId === 8 && <StaticImage src="../images/author/jennifermorency.jpg" alt={`blog author ${dataPost.author.node.name}`} layout="fixed" width={150} height={150} /> }
                {dataPost.author.node.databaseId === 3 && <StaticImage src="../images/author/stevenferguson.jpg" alt={`blog author ${dataPost.author.node.name}`} layout="fixed" width={150} height={150} /> }
                {dataPost.author.node.databaseId === 2 && <StaticImage src="../images/author/author-default.png" alt={`blog author ${dataPost.author.node.name}`} layout="fixed" width={150} height={150}  /> }
                {dataPost.author.node.databaseId === 15 && <StaticImage src="../images/author/author-default.png" alt={`blog author ${dataPost.author.node.name}`} layout="fixed" width={150} height={150}  /> }
              </div>
              <div className="infos-author first-body">
                <p>{dataPost.author.node.description}</p>
              </div>
            </div>
          </section>
          {/* Section with loop on related articles */}
          <section className="section-related-article">
            <div className="title-section-aside">
              <h2>Related Articles</h2>
            </div>
            <div className="wrap-grid-related-article">
              <div className="grid-related-article grid-loop-card-clickable">
                {data.relatedPost.edges.map(({ node }) => (
                  <Cardrelatedpost
                    key={node.databaseId}
                    titlePost={node.title}
                    srcImgPost={node.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
                    datePost={node.date}
                    typeSlugPost={node.categories.nodes[0].slug}
                    slugPost={node.slug} />
                ))}
              </div>
            </div>
          </section>
        </aside>
      </section>
      {/* Banner CTA*/}
      <Bannerwithcta
        classBanner="section-banner-post"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}
/* Export template informations */
export default PostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!, $tagid: [Int]) {
    wpPost(databaseId: { eq: $id }) {
      databaseId
      title
      slug
      content
      categories {
        nodes {
          name
          slug
        }
      }
      date (
        formatString: "MMMM DD, YYYY"
      )
      acfPostBlogNews {
        leadArticle
        imageArticle {
          localFile {
            childImageSharp {
              gatsbyImageData (layout: CONSTRAINED, width: 1000, aspectRatio: 1.5)
            }
          }
        }
        repeaterArticle {
          titleH2Section
          contentSection
          typeBlocAside
          blocAsideSection
        }
      }
      seo {
        title
        metaDesc
      }
      author {
        node {
          databaseId
          name
          description
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
    }
    relatedPost: allWpPost(filter: {databaseId: {ne: $id}, tags: {nodes: {elemMatch: {databaseId: {in: $tagid}}}}}, sort: {fields: [date], order:DESC}, limit: 3) {
      edges {
        node {
          databaseId
          title
          slug
          date (
            formatString: "MMMM DD, YYYY"
          )
          categories {
            nodes {
              slug
            }
          }
          acfPostBlogNews {
            leadArticle
            imageArticle {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 800, aspectRatio: 1.5)
                }
              }
            }
          }
        }
      }
    }
    allWpTag {
      edges {
        node {
          name
          slug
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
